import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "regeneration",
      "style": {
        "position": "relative"
      }
    }}>{`Regeneration`}<a parentName="h1" {...{
        "href": "#regeneration",
        "aria-label": "regeneration permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p><div parentName="p" {...{
        "className": "gatsby-resp-iframe-wrapper",
        "style": {
          "paddingBottom": "50%",
          "position": "relative",
          "height": "0",
          "overflow": "hidden"
        }
      }}>{` `}<div parentName="div" {...{
          "className": "embedVideo-container"
        }}>{` `}<iframe parentName="div" {...{
            "title": "",
            "src": "https://www.youtube.com/embed/veAk4TCTg9Q?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0",
              "position": "absolute",
              "top": "0",
              "left": "0",
              "width": "100%",
              "height": "100%"
            },
            "loading": "eager",
            "allowFullScreen": true,
            "sandbox": "allow-same-origin allow-scripts allow-popups"
          }}></iframe>{` `}</div>{` `}</div></p>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><em parentName="p">{`The red road lies bare before us,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`a path of joy, and life's good water`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`which wanders into rainbow fire`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`as it finds its way around the four`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`part turning harmony.`}</em>{`  `}</p>
        <p><em parentName="p">{`Hear, dear friends, that old vision`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`our grandparents of the good mind gave:`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`a hoop of many hoops woven together`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`hoping the tree might bloom again,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`though there is no king who will return.`}</em>{`  `}</p>
        <p><em parentName="p">{`It is us`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`who must roll in time's dust`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`and gather the ash of past fires`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`to help grow a garden that holds`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`the light, and shifts it into`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`something sweet,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`ripe, juicy,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`redemptive.`}</em></p>
      </Box>
    </InfoBlock>
    <p>{`Regeneration is a `}<a parentName="p" {...{
        "href": "https://www.etymonline.com/word/regeneration"
      }}>{`deep word`}</a>{`. Here, we seek to join finance with regeneration, creating what is sometimes called "ReFi". `}</p>
    <p>{`Bitcoin can be described as a shared database, which no-one has privileged access to, and which anyone can update, according to rules about which we all agree. The cost of running this database accrues to the people actually running it and this is what ReFi is, in its essential form. `}<strong parentName="p">{`ReFi is the consciously programmed flow of value, through specific incentives, to the people doing relevant work`}</strong>{`. For instance, a `}<a parentName="p" {...{
        "href": "/tokens/#the-cost-of-decisions"
      }}>{`regenerative governance system is one in which votes are simultaneous to the movement of valuable tokens`}</a>{` such that any proposal with enough funds can move ahead. `}<em parentName="p">{`Value is distributed by virtue of how we govern`}</em>{`, rather than after the fact when a few people have already made a decision.`}</p>
    <p>{`Other common examples of early ReFi tools include carbon credits or renewable energy certificates, though neither of these are unique to cryptocurrencies, which only serve to create more efficient markets for trading these more traditional instruments. We'll consider such tools and techniques in this section, but spend most of time looking together for more general patterns made possible by programmable money which `}<strong parentName="p">{`help us create more wholesome economic environments in which we can live well together`}</strong>{`, both with each other and with the more-than-human world. In this sense, it is no mistake we start with a poem:`}</p>
    <blockquote>
      <p parentName="blockquote">{`We need `}<a parentName="p" {...{
          "href": "https://mspapic.medium.com/evolution-is-an-act-of-art-ec3c872b1d28"
        }}>{`applied poetics`}</a>{` `}{`[...]`}{` we must be poetic. We must reimagine `}<a parentName="p" {...{
          "href": "https://www.youtube.com/watch?v=Ht55NnOr7m4"
        }}>{`the bridge not as a thing but as `}<strong parentName="a">{`a system of tensions`}</strong></a>{`. We must understand what it feels like to both reach, and to support.`}</p>
    </blockquote>
    <p>{`We love this idea of a system of tensions. Regenerative patterns are not green pills or silver bullets: they are intricate `}<a parentName="p" {...{
        "href": "https://norabateson.wordpress.com/2015/11/03/symmathesy-a-word-in-progress"
      }}>{`symmathesies`}</a>{` of strange balances, much like any organism. Thinking that there is one "just" or "right" solution is the exact method which has landed us all in a situation where we need to regenerate resources we have abused through our ongoing push to progress to "the end". There is `}<a parentName="p" {...{
        "href": "/learn/module-3/time/#brief"
      }}>{`no conclusion`}</a>{`: regeneration is not about some final and harmonious state, it is a never-ending story constantly trusted to your own interpretation:`}</p>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><em parentName="p">{`To follow the Way`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`is not to need fulfillment.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Unfulfilled, one may live on`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`needing no renewal.`}</em>{`  `}</p>
      </Box>
    </InfoBlock>
    <p>{`At the base of this work is our shared soil, which is the root of `}<a parentName="p" {...{
        "href": "/learn/module-3/humility/#how-does-this-fit-into-kernel"
      }}>{`humility`}</a>{`, and the water which births all life. We recommend going outside right now, touching the soil with your bare feet and washing your face and hands with water if you are able to do so. These kinds of simple rituals are not meant to have mystical implications: they are intended to ground you in practical awareness of - and gratitude for - the environment within which all of life occurs. Awareness breeds gratitude, which begets curiosity, which grows in knowledge, is cultivated into wisdom, and blossoms into love.`}</p>
    <blockquote>
      <p parentName="blockquote">{`It makes no sense in the least to think that art and science, or that art and farming, are not intertwined. Ask anyone what makes their colleague truly brilliant and it’s usually in their innovative and unique way of approaching a challenge. That innovation or singular approach requires a kind of internal poetry. Someone can teach you the laws of physics, but no one can exactly teach you which ones to question. `}<a parentName="p" {...{
          "href": "/learn/module-6/learn/#a-surprisingly-educated-public"
        }}>{`Schools can teach you but they can’t make you curious`}</a>{`.`}</p>
    </blockquote>
    <p>{`We are here to be curious together about everything from resilient resources to sacred economics as we explore tools, practices, and crafts which will help us live in balance, harmony, and health. `}<a parentName="p" {...{
        "href": "/start/principled-people"
      }}>{`Kernel's principles`}</a>{` can be simplified into just two statements, one for the web3 principles, and one for the personal principles. Web3 reduces down to "`}<strong parentName="p">{`do no harm`}</strong>{`" and the personal principles sum up to "`}<strong parentName="p">{`play, infinitely`}</strong>{`". This is what we intend to do during the regeneration guild.`}</p>
    <blockquote>
      <p parentName="blockquote">{`To be sure, no one person or program defines a movement, but each one plays an integral part. The systems design we seek is in not in a manual, nor in the soil, nor in a lab alone. The regenerative process lives in our hearts, in our very desire to keep trying even when our plans get re-written by fate.`}</p>
    </blockquote>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><em parentName="p">{`The whole wide world we traverse with our thoughts`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`And nothing that humans find is more dear than self.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Since, oh, so dear, the self to others is,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Those who know the love of self`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Must harm no other being.`}</em></p>
        <p><strong parentName="p">{`Samyutta-nikaya`}</strong>{`, pt 1, ch. 3`}</p>
      </Box>
    </InfoBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      